import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import './scss/general.scss'
import Layout from "layouts/Layout/Layout"
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import People from "pages/People/People"
import About from "pages/About/About"
import Contacts from "pages/Contacts/Contacts"
import ContentWithFilterLayout from "layouts/ContentWithFilterLayout/ContentWithFilterLayout"
import Blog from "pages/Blog/Blog"
import Library from "pages/Library/Library"
import { internazionalization } from './utils/i18n'
import LanguageDetector from 'i18next-browser-languagedetector'

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Album from "pages/Album/Album"

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(internazionalization)


const App = () => {

    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path={"*"} element={<Navigate to={"/library"}/>}/>
                    <Route
                        path={"/people"}
                        element={<People/>}
                    />
                    <Route
                        path={"/about"}
                        element={<About/>}
                    />
                    <Route
                        path={"/contacts"}
                        element={<Contacts/>}
                    />
                    <Route path={"library"}>
                        <Route element={<ContentWithFilterLayout/>}>
                            <Route
                                index
                                element={<Library/>}
                            />
                        </Route>
                        <Route
                            path={":id"}
                            element={<Album/>}
                        />
                    </Route>
                    <Route path={"/blog"}>
                        <Route element={<ContentWithFilterLayout/>}>
                            <Route
                                index
                                element={<Blog/>}
                            />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default App
