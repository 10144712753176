import { useQuery } from "@tanstack/react-query"
import { strapiAxios } from "api"

export const useGetMap = () => {
    return useQuery({
        queryKey: ["map"],
        queryFn: async () => {
            try {
                const response = await strapiAxios.get('/api/map')
                return response.data
            } catch (error) {
                console.log(error)
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    })
}