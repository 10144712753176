import React from 'react'
import './Filter.scss'
import Arrow from 'assets/icons/chevron.svg'
import { useGetCountries } from "generated/country/country"
import { useGetInstruments } from "generated/instrument/instrument"
import Checkbox from "components/Checkbox/Checkbox"
import { getArrayData } from "helpers"
import { useGetGenres } from "generated/genre/genre"
import { useGetNationalities } from "generated/nationality/nationality"
import { FormikValues, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

interface IFilter {
    name: string
    isCurrent: boolean
    setCurrent: () => void
}

const Filter: React.FC<IFilter> = (props) => {
    const { name, isCurrent, setCurrent } = props
    const { t } = useTranslation()

    const { values, submitForm } = useFormikContext<FormikValues>()
    const selectedFilterCount = values[name]?.length

    const getFilterFunction = (filter: string) => {
        switch (filter) {
            case "countries":
                return useGetCountries
            case "nationalities":
                return useGetNationalities
            case "genres":
                return useGetGenres
            default:
                return useGetInstruments
        }
    }

    const { data } = getFilterFunction(name)({
            "pagination[pageSize]": 500,
            sort: "name",
        },
        {
            query:
                {
                    queryKey: [name],
                    refetchOnMount: false,
                    refetchOnWindowFocus: false
                }
        })

    return (
        <div className={"filter"}>
            <div
                className={`filter__type filter__type--${isCurrent ? "open" : "closed"}`}
                onClick={() => setCurrent()}
            >
                {t(name)} {selectedFilterCount ? `(${selectedFilterCount})` : null}
                <img src={Arrow} alt="Arrow icon"/>
            </div>
            <div
                className={`filter__list filter__list--${isCurrent ? "open" : "closed"}`}
            >
                {getArrayData(data?.data?.data).map((item, index) => {
                    return (
                        <Checkbox
                            name={name}
                            key={`${name}-${index}`}
                        >
                            {item}
                        </Checkbox>
                    )
                })}
            </div>
        </div>
    )
}

export default Filter