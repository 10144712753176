import React from 'react'
import './Checkbox.scss'
import { Field, useField } from "formik"
import Cross from 'assets/icons/cross.svg'

interface ICheckbox extends React.HTMLAttributes<HTMLLabelElement> {
    name: string
    children: string
}

const Checkbox: React.FC<ICheckbox> = (props) => {
    const css = ["checkbox"]

    const value = props.children.toLowerCase()
    const [field, ,] = useField(props.name)
    const isChecked = field.value?.filter((item: string) => item === value)?.length
    if (isChecked) {
        css.push('checkbox--checked')
    }

    return (
        <label className={css.filter(Boolean).join(" ")}>
            <Field type={"checkbox"} name={props.name} value={value}/>
            <span>{props.children}</span>
            {isChecked ?
                <img src={Cross} alt="Cross icon"/>
                : null}
        </label>
    )
}

export default Checkbox