import { TTagName } from "types"
import { Album, AlbumCountriesDataItem, AlbumGenresDataItem, AlbumInstrumentsDataItem } from "model"

export const getAlbumTags = (album: Album) => {
    const tags: Record<TTagName, any[] | undefined> = {
        genres: album?.genres as AlbumGenresDataItem[],
        countries: album?.countries as AlbumCountriesDataItem[],
        instruments: album?.instruments as AlbumInstrumentsDataItem[],
        nationalities: album?.nationalities as AlbumInstrumentsDataItem[]
    }

    return tags
}