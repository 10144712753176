import './Blog.scss'
import TagsList from "components/TagsList/TagsList"
import Markdown from "react-markdown"
import Divider from "components/Divider/Divider"
import {
    AlbumCover,
} from "model"
import { getImageUrl } from "utils"
import Button from "components/Button/Button"
import { Link } from "react-router-dom"
import Loader from 'components/Loader/Loader'
import PageHeader from 'components/PageHeader/PageHeader'
import EmptyMessage from 'components/EmptyMessage/EmptyMessage'
import { useTranslation } from 'react-i18next'
import rehypeRaw from 'rehype-raw'
import { getAlbumTags } from "utils/getAlbumTags"
import { useGetPosts } from "hooks/useGetPosts"

const Blog = () => {
    const { t } = useTranslation()

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = useGetPosts()

    return (
        <div className={"blog"}>
            <PageHeader title={t("blogPageTitle")}/>
            <Divider margin={"0rem"}/>
            {isLoading ? <Loader/> :
                <div className={"blog__list"}>
                    {!data?.pages[0].data?.length ?
                        <EmptyMessage/>
                        :
                        data.pages.map((page, pageIndex) => {
                            const posts = page.data
                            return (
                                posts.map((post: any, postIndex: number) => {
                                    const attributes = post?.attributes

                                    const album = attributes?.albums?.data?.[0]?.attributes
                                    const tags = getAlbumTags(album)
                                    const publishedAt = attributes?.prevPublishedAt ?? attributes?.publishedAt
                                    const authors = attributes?.authors?.data
                                    const cover = album?.cover as AlbumCover
                                    const url = cover?.data?.attributes?.url
                                    const name = album?.name as string

                                    return (
                                        <>
                                            <div className={"blog__item"}>
                                                <Link
                                                    to={`/library/${attributes?.albums?.data?.[0]?.id}`}
                                                    className={"blog__cover"}
                                                >
                                                    <img src={getImageUrl(url)} alt=""/>
                                                </Link>
                                                <div className={"preview"}>
                                                    <p className={'preview__name fontSize-s fontWeight-bold'}>{name}</p>
                                                    <Markdown
                                                        rehypePlugins={[rehypeRaw]}
                                                        className={"markdown--small preview__text"}
                                                    >
                                                        {attributes.text}
                                                    </Markdown>
                                                    {/*<Divider margin={".5rem"}/>*/}
                                                    {/*<Authors*/}
                                                    {/*    authors={authors}*/}
                                                    {/*    publishedAt={getFormatDate(publishedAt)}*/}
                                                    {/*/>*/}
                                                    {/*<Divider margin={".5rem"}/>*/}
                                                    {Object.keys(tags)?.length ?
                                                        <TagsList
                                                            tags={tags}
                                                            hideTitle={true}
                                                            isPreview={true}
                                                        />
                                                        : null}
                                                </div>
                                            </div>
                                            <Divider margin={'1rem'}/>
                                        </>

                                    )
                                })
                            )
                        })
                    }
                </div>}
            {data?.pages[0].data?.length && hasNextPage ?
                <div className={"library__actions"}>
                    <Button
                        variant={"tertiary"}
                        onClick={() => fetchNextPage()}
                        icon={{
                            name: 'plus',
                            position: "left"
                        }}
                        disabled={!hasNextPage || isFetchingNextPage}
                    >
                        {t("buttonDownloadMore")}
                    </Button>
                </div>
                : null}
        </div>
    )
}

export default Blog