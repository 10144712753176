import { useInfiniteQuery } from '@tanstack/react-query'
import { strapiAxios } from 'api'
import AlbumCard from 'components/AlbumCard/AlbumCard'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'
import React from 'react'
import { useLocation } from 'react-router-dom'
import './AlbumsList.scss'
import { getQuery } from 'helpers'
import qs from 'qs'
import EmptyMessage from 'components/EmptyMessage/EmptyMessage'
import { useTranslation } from 'react-i18next'
import { useGetAlbums } from "hooks/useGetAlbums"

const AlbumsList = () => {
    const { t, i18n } = useTranslation()
    const location = useLocation()

    const query = qs.stringify({
        populate: "*",
        ...getQuery(location.search, i18n.language.split("-")[0], false),
    })

    const {
        data,
        isLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage
    } = useGetAlbums()

    console.log(data)

    if (isLoading) return <Loader/>

    if (!data?.pages[0]?.data.length) {
        return (
            <EmptyMessage/>
        )
    }

    return (
        <div className={"albums-list"}>
            <div className={"library__albums"}>
                {data.pages.map((page) => {
                    const albums = page.data
                    return (
                        // TODO ANY
                        albums.map((album: any) => {
                            return (
                                <AlbumCard
                                    {...album}
                                    key={album.id}
                                />
                            )
                        })
                    )
                })}
            </div>
            {hasNextPage ?
                <div className={"library__actions"}>
                    <Button
                        variant={"tertiary"}
                        onClick={() => fetchNextPage()}
                        icon={{
                            name: 'plus',
                            position: "left"
                        }}
                        disabled={!hasNextPage || isFetchingNextPage}
                    >
                        {t("buttonDownloadMore")}
                    </Button>
                </div>
                : null}
        </div>
    )
}

export default AlbumsList