import React, { useCallback } from 'react'
import './Album.scss'
import { useParams } from "react-router-dom"
import StreamingList from "components/StreamingList/StreamingList"
import SeeMore from "components/SeeMore/SeeMore"
import Divider from "components/Divider/Divider"
import TagsList from "components/TagsList/TagsList"
import { getFormatDate } from "utils"
import { useGetAlbumData } from "hooks"
import Markdown from "react-markdown"
import References from "components/References/References"
import Authors from "components/Authors/Authors"
import Share from "components/Share/Share"
import { useTranslation } from 'react-i18next'
import rehypeRaw from 'rehype-raw'
import Loader from 'components/Loader/Loader'
import { getAlbumTags } from "utils/getAlbumTags"
import { useGetAlbumById } from "hooks/useGetAlbumById"

const Album = () => {
    const { id } = useParams()
    const { t, i18n } = useTranslation()

    const {
        data: albumResponse,
        isLoading: isAlbumLoading
    } = useGetAlbumById({ id })

    console.log(i18n)
    console.log(albumResponse)
    const album = albumResponse?.attributes
    const post = album?.posts?.data?.filter((post: any) => post?.attributes?.locale === i18n.language.split("-")[0])?.[0]?.attributes
    const authors = post?.authors?.data

    const {
        name,
        imageLink,
        artist,
        year,
        labels,
        streamings
    } = useGetAlbumData(album)

    const publishedAt = post?.prevPublishedAt ?? post?.publishedAt
    // edited в posts не добавляем, потому что когда этот пост со старыми данными будет обновлен, то обновления не увидим
    const updatedAt = post?.updatedAt

    const spotifyLink = useCallback((link: string | undefined) => {
        if (!link) return ""
        const splittedLink = link.split('/').splice(-2)
        return `https://open.spotify.com/embed/${splittedLink.join("/")}`
    }, [])

    const tags = getAlbumTags(album)

    const hasRecommendations = album?.countries?.data?.length || album?.genres?.data?.length || album?.instruments?.data?.length || album?.nationalities?.data?.length
    const hasStreamingBesidesSpotify = Object?.entries(streamings as Record<string, string> ?? {})
        ?.filter((entries, index) => {
            if (entries[0] === "id" || entries[0] === "spotify" || !entries[1]) {
                return false
            } else {
                return true
            }
        })?.length

    return (
        <div className={"post"}>
            {isAlbumLoading ?
                <Loader/> :
                <>
                    <div className={"post__album"}>
                        <img
                            className={"post__cover"}
                            src={imageLink}
                            alt=""
                        />
                        <div className={"post__information flex--column gap--s jcc"}>
                            <span className={"fontSize-l fontWeight-bold"}>{artist}</span>
                            <span className={"fontSize-xxl"}>{!album?.isHideInLibrary ? name : post?.name}</span>
                            {labels && year ?
                                <span className={"fontSize-m"}>{`${labels}, ${year}`}</span>
                                : null}
                        </div>
                        <div className={"post__authors"}>
                            <Divider margin={"1rem"}/>
                            <div className={"post__author"}>
                                <Authors authors={authors} publishedAt={getFormatDate(publishedAt)}/>
                                <Share/>
                            </div>
                        </div>
                    </div>
                    <Divider margin={"1rem"}/>
                    <div className={"post__text flex--column gap--xl"}>
                        <Markdown
                            rehypePlugins={[rehypeRaw]}
                            className={"markdown"}
                        >
                            {post?.text}
                        </Markdown>
                        <div>
                                <span
                                    className={"fontSize-xs post__date"}
                                >
                                    {t('postPublish')} {getFormatDate(publishedAt)}
                                </span>
                            <br/>
                            <span
                                className={"fontSize-xs post__date"}>({t("postUpdated")} {getFormatDate(updatedAt)})
                            </span>
                        </div>
                    </div>
                    <Divider margin={"1rem"}/>
                    <div className={"flex--column gap--xl"}>
                        {streamings?.spotify ?
                            <div className={"fontSize-xs"}>
                                {t("streamingDisclamer")}
                            </div>
                            : null}
                        <div
                            className={`${streamings?.spotify && hasStreamingBesidesSpotify ? "post__streamings" : ""}`}>
                            {streamings?.spotify ?
                                <iframe
                                    style={{ borderRadius: "12px" }}
                                    src={spotifyLink(streamings?.spotify)}
                                    width="100%"
                                    height="352"
                                    title={"Spotify"}
                                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                                    loading="lazy"
                                    frameBorder={"0"}
                                ></iframe>
                                : null
                            }
                            {hasStreamingBesidesSpotify ?
                                <StreamingList {...streamings} />
                                : null
                            }
                        </div>
                    </div>
                    <Divider margin={"1rem"}/>
                    {Object.keys(tags)?.length ?
                        <TagsList tags={tags}/>
                        : null}
                    {post?.references?.length ?
                        <>
                            <Divider margin={"1rem"}/>
                            <References references={post?.references}/>
                        </>
                        : null}
                    {hasRecommendations &&
                        <>
                            <Divider margin={"1rem"}/>
                            <SeeMore album={album} id={id}/>
                        </>}
                </>
            }
        </div>
    )
}

export default Album