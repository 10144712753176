import { strapiAxios } from "api"
import { useInfiniteQuery } from "@tanstack/react-query"
import { useLocation } from "react-router-dom"
import qs from "qs"

export const useGetAlbums = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)

    return useInfiniteQuery({
        queryKey: ['albums', {
            searchParams: location.search
        }],
        queryFn: async ({ pageParam }) => {
            try {
                const filters: any[] = []
                searchParams.forEach((query, key) => {
                    filters.push({
                        [key]: {
                            $or: query.split(",").map((item) => {
                                return {
                                    name: {
                                        $eqi: item
                                    }
                                }
                            })
                        }
                    })
                })

                const query = qs.stringify({
                    populate: {
                        artists: {
                            fields: ["name"]
                        },
                        cover: {
                            fields: ["url"]
                        },
                        label: {
                            fields: ["name"]
                        }
                    },
                    "pagination[page]": pageParam,
                    "pagination[pageSize]": 12,
                    filters: {
                        $and: [
                            {
                                $or: filters,
                            },
                            {
                                $or: [
                                    {
                                        isHideInLibrary: {
                                            $null: true
                                        }
                                    },
                                    {
                                        isHideInLibrary: {
                                            $ne: true
                                        }
                                    }
                                ]
                            }
                        ]

                    },
                    sort: ['createdAt:desc'], // Сортировка по createdAt в порядке убывания
                })
                const response = await strapiAxios.get(`/api/albums?${query}`)
                return response.data
            } catch (error) {
                console.log(error)
            }
        },
        initialPageParam: 0,
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {
            console.log(lastPage)
            const currentPage: number = lastPage.meta.pagination.page
            if (currentPage < lastPage.meta.pagination.pageCount) {
                return currentPage + 1
            }
        },
        refetchOnMount: false
    })
}