import { useInfiniteQuery } from "@tanstack/react-query"
import qs from "qs"
import { strapiAxios } from "api"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"

export const useGetPosts = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const { i18n } = useTranslation()

    return useInfiniteQuery({
        queryKey: ['posts', {
            searchParams: location.search,
        }],
        queryFn: async ({ pageParam }) => {
            try {
                const filters: any[] = []

                searchParams.forEach((query, key) => {
                    filters.push({
                        [key]: {
                            $or: query.split(",").map((item) => {
                                return {
                                    name: {
                                        $eqi: item
                                    }
                                }
                            })
                        }
                    })
                })

                const query = qs.stringify({
                    populate: {
                        albums: {
                            fields: ['name'], // Поля из albums
                            populate: {
                                genres: {
                                    fields: ['name'], // Поля из genres
                                },
                                nationalities: {
                                    fields: ['name'], // Поля из genres
                                },
                                countries: {
                                    fields: ['name'], // Поля из genres
                                },
                                instruments: {
                                    fields: ['name'], // Поля из genres
                                },
                                cover: {
                                    fields: ["url"]
                                },
                                albums: {
                                    fields: ["id"]
                                }
                            },
                        },
                    },
                    "pagination[page]": pageParam,
                    "pagination[pageSize]": 12,
                    filters: {
                        $or: filters,
                    },
                    sort: ['createdAt:desc'], // Сортировка по createdAt в порядке убывания
                    locale: i18n.language.split("-")[0]
                })
                const response = await strapiAxios.get(`/api/posts?${query}`)
                return response.data
            } catch (error) {
                console.log(error)
            }
        },
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages) => {
            const currentPage: number = lastPage.meta.pagination.page
            if (currentPage < lastPage.meta.pagination.pageCount) {
                return currentPage + 1
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    })
}