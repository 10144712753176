export const makeObjectFromQuery = (searchParams: string, object: Record<string, any>) => {
    const [criteria, queries] = searchParams.split("=")

    const queryArray: string[] = queries
        .split('%26')
        .map((query) =>
            decodeURI(query)
                .replaceAll("%20", " ")
                .replaceAll("%2520", " "))

    object[criteria] = {
        name: {
            $eqi: queryArray
        }
    }
}

export const getQuery = (query: string = "", locale?: string, isBlog: boolean = false) => {
    const queryObject = {}

    if (query) {
        const searchParams = query.slice(1)
        const filters = searchParams.split("&")
        filters.forEach((filter) => {
            makeObjectFromQuery(filter, queryObject)
        })
    }

    return queryObject
}

export function getArrayData<T>(data: T | undefined): string[] {
    const authorsArray: string[] = []
    if (!data) return authorsArray
    if (!Array.isArray(data)) {
        throw new TypeError(`data is not an Array`)
    }
    data?.forEach((item) => {
        if (typeof item?.attributes?.name === "string") {
            authorsArray.push(item?.attributes?.name)
        }
    })
    return authorsArray
}

export const getStyles = (
    baseClassName: string,
    dependencies: Array<string | {
        className: string,
        isTrue: boolean | undefined
    }>
) => {
    const styles: Array<string> = [baseClassName]
    dependencies.forEach((dependency) => {
        if (dependency === undefined) {
            return
        }
        if (typeof dependency === "string") {
            styles.push(`${baseClassName}--${dependency}`)
            return
        }
        if (Boolean(dependency.isTrue)) {
            styles.push(`${baseClassName}--${dependency.className}`)
        }
    })
    return styles.join(" ")
}