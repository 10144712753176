import React, { useEffect } from "react"
import SvgMap from "svgmap"
import "svgmap/dist/svgMap.min.css"
import "./Map.scss"
import { useNavigate } from "react-router-dom"
import Divider from "components/Divider/Divider"
import { setCountryLinks } from "components/Map/helpers"
import { strapiAxios } from "api"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useGetMap } from "hooks/useGetMap"

const Map = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data: map } = useGetMap()

    useEffect(() => {
        if (!map) return

        const handleClick = (event: any) => {
            event.preventDefault()
            const country: string = event.target.id.substring(
                event.target.id.length - 2
            )
            navigate(`?countries=${map[country].toLowerCase()}`)
        }

        new SvgMap({
            targetElementID: "svgMap",
            // showZoomReset: true,
            flagType: "emoji",
            noDataText: t('albumsNotFound'),
            touchLink: true,
            data: {
                data: {
                    albums: {
                        name: t("albumName"),
                        format: "{0}",
                        thousandSeparator: " ",
                    },
                },
                applyData: "albums",
                values: setCountryLinks(map),
            },
            colorMax: '#363636',
            colorMin: '#939393',
            colorNoData: '#F3F3F3'
        })


        for (let key of Object.keys(map)) {
            const country: HTMLElement | null = document.getElementById(
                `svgMap-map-country-${key}`
            )
            if (map[key]["albums"] > 0) {
                country?.addEventListener("onMouseUp", handleClick)
            }
        }


        return () => {
            const svgMap: HTMLElement | null = document.getElementById("svgMap")
            svgMap?.children[0].remove()

            for (let key of Object.keys(map)) {
                const country: HTMLElement | null = document.getElementById(
                    `svgMap-map-country-${key}`
                )
                country?.removeEventListener("onMouseUp", handleClick)
            }

            const svgToolTip: HTMLCollectionOf<Element> | null =
                document.getElementsByClassName("svgMap-tooltip")
            for (let i = 0; i < svgToolTip.length; i++) {
                svgToolTip[i].remove()
            }
        }
    }, [map])

    return (
        <>
            <div id="svgMap"></div>
            <Divider margin={'1rem'}/>
        </>
    )
}

export default Map