import React from 'react'
import './Button.scss'
import { getStyles } from 'helpers'
import { IIcon } from 'types'
import Icon from 'components/Icon/Icon'

type ButtonVariant = "primary" | "secondary" | "tertiary" | "icon"


interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: ButtonVariant
    icon?: IIcon
    fullWidth?: boolean
}

const Button: React.FC<IButton> = (props) => {
    const {
        children,
        icon =  null,
        variant = "primary",
        fullWidth,
        ...rest
    } = props

    return (
        <button
            {...rest}
            className={getStyles("button", [variant, { className: "full-width", isTrue: fullWidth }])}
        >
            {children}
            {icon ?
                <Icon
                    {...icon}
                />
                : null}
        </button>
    )
}

export default Button