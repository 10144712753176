import { useQuery } from "@tanstack/react-query"
import { strapiAxios } from "api"

export const useGetFilters = () => {
    return useQuery<string[]>({
        queryKey: ["filters"],
        queryFn: async () => {
            try {
                const response = await strapiAxios.get("/api/filter?fields=filters")
                return response.data.data.attributes.filters
            } catch (error) {
                console.error(error)
            }
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    })
}
