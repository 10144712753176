import React, { useEffect, useState } from 'react'
import './FilterList.scss'
import Filter from "components/Filter/Filter"
import { Form, Formik, FormikValues } from "formik"
import Button from "components/Button/Button"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useGetFilters } from "hooks/useGetFilters"

const FilterList = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [values, setValues] = useState<FormikValues>({})
    const [current, setCurrent] = useState<number>(-1)
    const { t } = useTranslation()

    useEffect(() => {
        const obj: Record<string, string[]> = {}
        searchParams.forEach((value, key) => {
            obj[key] = searchParams.getAll(key)[0].split(",")
        })
        setValues(obj)
    }, [searchParams])

    const {
        data: filters,
        isLoading: isFiltersLoading
    } = useGetFilters()

    const handleSubmit = (values: FormikValues) => {
        const filters: Record<string, string> = {}
        Object.entries(values).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length === 0) return
                filters[key] = value.join(",")
            }
        })
        setSearchParams(filters)
    }

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={values}
            enableReinitialize={true}
        >
            {({ values, dirty }) => {
                return (
                    <Form>
                        <div className={"filter-list"}>
                            {Array.isArray(filters) && filters.map((filter, index) => {
                                return (
                                    <Filter
                                        name={filter}
                                        key={`${filter}-${index}`}
                                        setCurrent={() => {
                                            if (current === index) {
                                                setCurrent(-1)
                                            } else {
                                                setCurrent(index)
                                            }
                                        }}
                                        isCurrent={current === index}
                                    />
                                )
                            })}
                            <div className={"filter-list__actions"}>
                                <Button
                                    type={"submit"}
                                    variant={"primary"}
                                    disabled={!dirty}
                                >
                                    {t("buttonSearch")}
                                </Button>
                                <Button
                                    type={"reset"}
                                    variant={"secondary"}
                                    onClick={() => {
                                        setSearchParams({})
                                    }}
                                >
                                    {t("buttonClear")}
                                </Button>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default FilterList