import React from 'react'
import './AlbumCard.scss'
import { Link } from "react-router-dom"
import { Album, AlbumListResponseDataItem } from "model"
import { getImageUrl } from "utils"
import { getArrayData } from "helpers"
import { useTranslation } from 'react-i18next'

const AlbumCard: React.FC<AlbumListResponseDataItem> = (props) => {
    const { attributes, id } = props
    const { i18n } = useTranslation()

    const getImageInfo = (attributes: Album | undefined) => {
        if (!attributes) return ""
        const cover = attributes?.cover?.data?.attributes?.url
        return getImageUrl(cover)
    }
    
    return (
        <Link
            className="album-card"
            to={String(id)}
        >
            <img
                src={getImageInfo(attributes)}
                alt={`${attributes?.name} cover`}
                className={"album-card__image"}
            />
            <span className="album-card__description">
                <span className={"album-card__name fontSize-xs"}>{attributes?.name}</span>
                <span
                    className={"album-card__author fontSize-xs"}
                >
                    {getArrayData(attributes?.artists?.data).join(", ")}
                </span>
            </span>
        </Link>
    )
}

export default AlbumCard
