import React, { useState } from 'react'
import './Library.scss'
import PageHeader from "components/PageHeader/PageHeader"
import Divider from "components/Divider/Divider"
import Map from "components/Map/Map"
import Button from "components/Button/Button"
import AlbumsList from 'components/AlbumsList/AlbumsList'
import { useTranslation } from 'react-i18next'

const Library = () => {
    const [isMapShown, setIsMapShown] = useState<boolean>(true)
    const { t } = useTranslation()

    return (
        <div className={"library"}>
            <PageHeader
                title={t("libraryPageTitle")}
                right={
                    <Button
                        variant={"secondary"}
                        onClick={() => {
                            setIsMapShown((prev) => !prev)
                        }}
                    >
                        {isMapShown ? t("buttonHideMap") : t("buttonShowMap")}
                    </Button>
                }
            />
            <Divider margin={"1rem"}/>
            {isMapShown ?
                <Map/>
                : null}
            <AlbumsList/>
        </div>
    )
}

export default Library