import { useQuery } from "@tanstack/react-query"
import { strapiAxios } from "api"

export const useGetAlbumById = (props: { id: string | undefined }) => {
    const { id } = props

    return useQuery({
        queryKey: ["albums", { id }],
        queryFn: async () => {
            try {
                const response = await strapiAxios.get(`/api/albums/${id}?populate=*`)
                return response.data.data
            } catch (error) {
                console.log(error)
            }
        },
        enabled: !!id
    })
}